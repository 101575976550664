import { IFetchConfig } from "@elsa-esa/lib/helpers/fetch";
import fetch from "./http-client";

import { CreateServerDto, Server, ServerChannel, UpdateServerDto } from "./data-contracts";

export const serversControllerCreate = (data: CreateServerDto, params?: IFetchConfig) =>
  fetch<Server>("POST", `servers`, data, params);

export const serversControllerFindAll = (params?: IFetchConfig) => fetch<Server[]>("GET", `servers`, null, params);

export const serversControllerFindOne = (id: number, params?: IFetchConfig) =>
  fetch<Server>("GET", `servers/${id}`, null, params);

export const serversControllerUpdate = (id: number, data: UpdateServerDto, params?: IFetchConfig) =>
  fetch<Server>("PUT", `servers/${id}`, data, params);

export const serversControllerRemove = (id: number, params?: IFetchConfig) =>
  fetch<void>("DELETE", `servers/${id}`, null, params);

export const serversControllerGetCameras = (id: number, params?: IFetchConfig) =>
  fetch<ServerChannel[]>("GET", `servers/${id}/cameras`, null, params);

export const serversControllerGetPreview = (id: number, cameraGuid: string, params?: IFetchConfig) =>
  fetch<File>("GET", `servers/${id}/cameras/${cameraGuid}/preview`, null, params);
