import { IFetchConfig } from "@elsa-esa/lib/helpers/fetch";
import fetch from "./http-client";

import { CreatePlaceDto, Place, UpdatePlaceDto } from "./data-contracts";

export const placesControllerCreate = (data: CreatePlaceDto, params?: IFetchConfig) =>
  fetch<Place>("POST", `places`, data, params);

export const placesControllerFindAll = (params?: IFetchConfig) => fetch<Place[]>("GET", `places`, null, params);

export const placesControllerFindOne = (id: number, params?: IFetchConfig) =>
  fetch<Place>("GET", `places/${id}`, null, params);

export const placesControllerUpdate = (id: number, data: UpdatePlaceDto, params?: IFetchConfig) =>
  fetch<Place>("PUT", `places/${id}`, data, params);

export const placesControllerRemove = (id: number, params?: IFetchConfig) =>
  fetch<void>("DELETE", `places/${id}`, null, params);
