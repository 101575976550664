import { useStore, UseStoreOptions } from "@nanostores/react";
import { action, map } from "nanostores";
import { appControllerCheckAuth } from "src/api/auth";
import { setApiAuthorization } from "src/api/http-client";

export type UserStore = {
  isAuth: boolean,
}

export const userStore = map<UserStore>({
  isAuth: null,
})

export const checkAuth = () => {
  const token: string | null = localStorage.token;
  if (!token) return unauth();
  auth(token);
}

export const auth = action(userStore, 'auth', (store, token: string) => {
  setToken(token);
  return appControllerCheckAuth().then(
    () => {
      store.set({ isAuth: true });
    },
    (error) => {
      unauth();
      throw error;
    },
  );
});

export const unauth = action(userStore, 'unauth', (store) => {
  store.set({ isAuth: false });
  setToken(null);
});

export const logout = () => unauth()

export const setToken = (accessToken: string) => {
  if (accessToken) {
    localStorage.token = accessToken;
  } else {
    delete localStorage.token;
  }
  setApiAuthorization(`Basic: ${accessToken}`);
}

export type UseUserOptions = UseStoreOptions<typeof userStore>;
export const useUser = (options?: UseUserOptions) => useStore(userStore, options);
