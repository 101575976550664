import { MenuGroup } from "@elsa-esa/lib/components/Sidebar";

const menu: MenuGroup[] = [
  {
    title: 'Камеры',
    items: [
      {
        url:      "/cameras",
        title:    "Камеры",
        icon:     require("feather-icons/dist/icons/video.svg"),
      },
      {
        url:      "/places",
        title:    "Объекты",
        icon:     require("feather-icons/dist/icons/home.svg"),
      },
      {
        url:      "/servers",
        title:    "Сервера",
        icon:     require("feather-icons/dist/icons/server.svg"),
      },
    ],
  },
];

menu.forEach(g => g.items.forEach(i => i.exact = true));

export default menu;
