import { IFetchConfig } from "@elsa-esa/lib/helpers/fetch";
import fetch from "./http-client";

import { IceServer, StreamInfo, StreamWebRTCInfo } from "./data-contracts";

export const streamControllerGetIce = (params?: IFetchConfig) => fetch<IceServer[]>("GET", `stream/ice`, null, params);

export const streamControllerGetStreamWebrtc = (
  query: {
    server: number;
    deviceId: string;
    type: "main" | "sub" | "archive_main" | "archive_sub";
  },
  params?: IFetchConfig,
) => fetch<StreamWebRTCInfo>("GET", `stream/get-webrtc`, query, params);

export const streamControllerGetStreamInfo = (
  query: {
    server: number;
    deviceId: string;
    type: "main" | "sub" | "archive_main" | "archive_sub";
  },
  params?: IFetchConfig,
) => fetch<StreamInfo>("GET", `stream/get-info`, query, params);
