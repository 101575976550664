import "@elsa-esa/lib/helpers/catchChunkLoadError";
import 'destyle.css';
import "@elsa-esa/lib/styles/_colors.scss";
import "@elsa-esa/lib/styles/_fontFaceMontserrat.scss";
import "@elsa-esa/lib/styles/_typography.scss";

import * as React from 'react';

import routes from "src/routes";
import menu from "src/menu";
import App from "@elsa-esa/lib/components/App";
import { createRoot } from "react-dom/client";
import { setApiUrl } from "src/api";
import { onError } from "@elsa-esa/lib/helpers/fetch";
import { useNavigate } from "react-router";
import { checkAuth, unauth, useUser } from "src/store/user";

if (process.env.NODE_ENV === 'production') setApiUrl(location.origin + '/api/');
checkAuth();

const app = (
  <App
    routes={routes}
    menu={menu}
    defaultRouteUrl="/cameras"
    baseUrl={''}
    renderRouter={(renderer) => {
      const { isAuth } = useUser();
      const navigate = useNavigate();

      React.useEffect(() => {
        return onError((e) => {
          if (e.status === 401) unauth();
        });
      }, []);
      //
      React.useEffect(() => {
        if (isAuth === false) navigate('/login');
      }, [isAuth]);

      return renderer();
    }}

    // renderRouter={(renderer) => renderer()}
    additionalRoutes={[
      {
        path: '/login',
        lazy: () => import('src/routes/Login').then(({ default: Component }: any) => ({ Component })),
      },
    ]}
  />
);

const root = createRoot(document.getElementById('app'));
root.render(app);
