import { IFetchConfig } from "@elsa-esa/lib/helpers/fetch";
import fetch from "./http-client";

import { Camera, CameraFov, CreateCameraDto, UpdateCameraDto } from "./data-contracts";

export const camerasControllerCreate = (data: CreateCameraDto, params?: IFetchConfig) =>
  fetch<Camera>("POST", `cameras`, data, params);

export const camerasControllerFindAll = (
  query?: {
    place?: number;
    server?: number;
    name?: string;
    guid?: string;
  },
  params?: IFetchConfig,
) => fetch<Camera[]>("GET", `cameras`, query, params);

export const camerasControllerGetFovs = (params?: IFetchConfig) =>
  fetch<CameraFov[]>("GET", `cameras/fovs`, null, params);

export const camerasControllerFindOne = (id: number, params?: IFetchConfig) =>
  fetch<Camera>("GET", `cameras/${id}`, null, params);

export const camerasControllerUpdate = (id: number, data: UpdateCameraDto, params?: IFetchConfig) =>
  fetch<Camera>("PUT", `cameras/${id}`, data, params);

export const camerasControllerRemove = (id: number, params?: IFetchConfig) =>
  fetch<void>("DELETE", `cameras/${id}`, null, params);
