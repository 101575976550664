import { RouteItem } from "@elsa-esa/lib/components/App/components/Content";

const routes: RouteItem[] = [
  {
    path:      '/cameras',
    name:      'cameras',
    component: () => import('src/routes/Cameras'),
  },
  {
    path:      '/places',
    name:      'places',
    component: () => import('src/routes/Places'),
  },
  {
    path:      '/places/:id',
    name:      'place',
    component: () => import('src/routes/Place'),
  },
  {
    path:      '/servers',
    name:      'servers',
    component: () => import('src/routes/Servers'),
  },
  {
    path:      '/servers/:serverId',
    name:      'server',
    component: () => import('src/routes/Server'),
  },
  {
    path:      '*',
    name:      'notFound',
    component: () => import('@elsa-esa/lib/components/NotFoundRoute'),
  },
];

export default routes;
